* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;

  @include respond(wide-screen) {
    font-size: 75%;
  }

  @include respond(tablet) {
    font-size: 16px;
  }
}

body {
  @include fs;
  @include fw;
  line-height: 1.5;
  font-family: $font-inter, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: $font-color;
}
