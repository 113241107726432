* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

@media (width >= 1590px) {
  html {
    font-size: 75%;
  }
}

@media (width <= 1100px) {
  html {
    font-size: 16px;
  }
}

body {
  color: #94a3b8;
  font-family: Inter, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

.app {
  background-color: #0f172a;
  width: 100vw;
  position: relative;
}

.app .dropShadow {
  pointer-events: none;
  z-index: 100;
  background: radial-gradient(600px at 0 0, #1d4ed826, #0000 80%);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  inset: 0;
}

@media (width <= 1100px) {
  .app .dropShadow {
    display: none;
  }
}

.app .container {
  gap: 10rem;
  max-width: 130rem;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 5rem;
  display: flex;
}

@media (width <= 1190px) {
  .app .container {
    gap: 5rem;
    max-width: 110rem;
    padding: 0 3rem;
  }
}

@media (width <= 1100px) {
  .app .container {
    flex-direction: column;
    gap: 10rem;
    max-width: 100vw;
  }
}

.app .container .header {
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100vh;
  padding: 6rem 0;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width <= 1100px) {
  .app .container .header {
    gap: 2rem;
    width: 90vw;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 3rem;
    padding-bottom: 0;
    position: relative;
  }
}

.app .container .header-profile {
  align-items: center;
  gap: 3rem;
  display: flex;
}

@media (width <= 1100px) {
  .app .container .header-profile {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10rem;
  }
}

.app .container .header-profile--userImage {
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
  transform: translateY(1rem);
}

@media (width <= 1100px) {
  .app .container .header-profile--userImage {
    width: 15rem;
    height: 15rem;
    transform: translateY(2rem);
  }
}

.app .container .header-profile--userInfo h1 {
  color: #e2e8f0;
  margin-bottom: 1rem;
  font-size: 4.8rem;
  font-weight: 700;
}

.app .container .header-profile--userInfo h2 {
  color: #e2e8f0;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
}

.app .container .header-nav {
  flex-direction: column;
  gap: 2px;
  display: flex;
}

@media (width <= 1100px) {
  .app .container .header-nav {
    display: none;
  }
}

.app .container .header-nav .navItem {
  color: #94a3b8;
  align-items: center;
  gap: 1rem;
  max-width: 15rem;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.app .container .header-nav .navItem.active, .app .container .header-nav .navItem:hover {
  color: #e2e8f0;
}

.app .container .header-nav .navItem.active .dash, .app .container .header-nav .navItem:hover .dash {
  background-color: #e2e8f0;
  width: 6.5rem;
}

.app .container .header-nav .navItem .dash {
  background-color: #94a3b8;
  width: 3rem;
  height: 1px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.app .container .header-contact {
  gap: 3rem;
  list-style: none;
  display: flex;
}

.app .container .header-contact .contactInfo svg {
  fill: #94a3b8;
  width: 3rem;
  height: 3rem;
}

.app .container .header-contact .contactInfo svg:hover {
  fill: #e2e8f0;
}

.app .container .main {
  width: 50%;
  margin-bottom: 10rem;
  padding: 6rem 0;
}

@media (width <= 1100px) {
  .app .container .main {
    width: 100%;
    padding-top: 0;
    padding-bottom: 3rem;
  }
}

.app .container .main .welcomeImage {
  width: 75%;
}

.app .container .main-content {
  margin-bottom: 10rem;
}

.app .container .main-content.about p:not(:last-child) {
  margin-bottom: 2rem;
}

.app .container .main-content.experience .experience-list {
  margin-bottom: 5rem;
}

.app .container .main-content.experience .experience-list--item {
  align-items: flex-start;
  gap: 3rem;
  display: flex;
  position: relative;
}

.app .container .main-content.experience .experience-list--item:first-child {
  margin-bottom: 5rem;
}

.app .container .main-content.experience .experience-list--item:hover h1 {
  color: #5eead4;
}

.app .container .main-content.experience .experience-list--item:hover .resumeShadow {
  opacity: 1;
}

.app .container .main-content.experience .experience-list--item .resumeShadow {
  filter: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  opacity: 0;
  background-color: #1e293b80;
  border-radius: 1rem;
  transition: all .3s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  inset: -1.5rem -2.5rem;
  box-shadow: 0 0 #0000, 0 0 #0000, inset 0 1px #94a3b81a;
}

@media (width <= 1100px) {
  .app .container .main-content.experience .experience-list--item .resumeShadow {
    display: none;
  }
}

.app .container .main-content.experience .experience-list--item .list-content {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  width: 30%;
  margin-top: .5rem;
  font-size: 1.4rem;
  display: flex;
}

.app .container .main-content.experience .experience-list--item .list-content .dash {
  background-color: #94a3b8;
  width: 1.5rem;
  height: 1px;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.app .container .main-content.experience .experience-list--item section {
  width: 60%;
}

.app .container .main-content.experience .experience-list--item section h1 {
  color: #e2e8f0;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.app .container .main-content.experience .experience-list--item section h2 {
  margin-bottom: .5rem;
  font-size: 1.4rem;
}

.app .container .main-content.experience .experience-list--item section p {
  margin-bottom: 1rem;
}

.app .container .main-content.experience .experience-list--item section ul {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.app .container .main-content.experience .experience-list--item section ul.skill-item li {
  color: #5eead4;
  background-color: #2dd4bf1a;
  border-radius: 100rem;
  padding: .5rem 1rem;
  font-size: 1.2rem;
  list-style: none;
}

.app .container .main-content.experience .resume {
  color: #e2e8f0;
  align-items: center;
  padding: 1rem 0;
  text-decoration: none;
  display: inline-flex;
}

.app .container .main-content.experience .resume svg {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .5rem;
  transition: transform .3s cubic-bezier(.4, 0, .2, 1);
}

.app .container .main-content.experience .resume:hover {
  border-bottom: 1px solid #5eead4;
}

.app .container .main-content.experience .resume:hover svg {
  transform: translateX(.5rem);
}

.app .container .main-footer {
  color: #64748b;
  font-size: 1.4rem;
}

.app .container .main-footer a:link, .app .container .main-footer a:visited {
  color: #94a3b8;
  font-weight: 500;
  text-decoration: none;
}

.app .container .main-footer a:hover, .app .container .main-footer a:active {
  color: #5eead4;
}

.app .container .giffy {
  padding: 2.5rem 5rem;
  transition: all .3s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 0;
  right: 0;
}

.app .container .giffy:hover {
  transform: translateY(-1rem)scale(1.2);
}

@media (width <= 1100px) {
  .app .container .giffy {
    padding: 1rem 2rem;
  }
}

/*# sourceMappingURL=index.ca00257f.css.map */
